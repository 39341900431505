import { BalanceReturn } from "../../entities/csg/BalanceReturn"
import { SearchParams, SearchResult } from "../../entities"
import ApiClient from "../clients/ApiClient"
import QueryStringBuilder from "../../util/queryBuilder"

const client = new ApiClient()

export class BalanceReturnRepository {
  public static async search(
    params: SearchParams,
    token: string
  ): Promise<SearchResult<BalanceReturn>> {
    const queryStringBuilder = new QueryStringBuilder()
    for (const filter of params.filter) {
      queryStringBuilder.addParameter(filter.key, filter.value)
    }
    const queryString = queryStringBuilder.buildQueryString()

    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest(
      "GET",
      `/balance-return?${queryString}&page=${params.page}&limit=${params.limit}`,
      {},
      headers
    )

    const result = new SearchResult<BalanceReturn>(response.data, response.info)

    return result
  }

  public static async csv(
    params: SearchParams,
    token: string
  ): Promise<string> {
    const headers = { Authorization: `Bearer ${token}` }
    const queryStringBuilder = new QueryStringBuilder()
    queryStringBuilder.addParameter("csv", "1")
    for (const filter of params.filter) {
      queryStringBuilder.addParameter(filter.key, filter.value)
    }
    const queryString = queryStringBuilder.buildQueryString()

    const response = await client.DoRequest(
      "GET",
      `/balance-return?${queryString}&page=${params.page}&limit=${params.limit}`,
      {},
      headers,
      true
    )

    return URL.createObjectURL(response)
  }

  public static async getBalance(token: string): Promise<BalanceReturn> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest(
      "GET",
      `/balance-return`,
      {},
      headers
    )

    return new BalanceReturn(response)
  }

  public static async get(id: number, token: string): Promise<BalanceReturn> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest(
      "GET",
      `/balance-return/${id}`,
      {},
      headers
    )

    return new BalanceReturn(response)
  }

  public static async update(
    id: number,
    status: string,
    token: string
  ): Promise<void> {
    const headers = { Authorization: `Bearer ${token}` }
    await client.DoRequest(
      "PUT",
      `/balance-return/${id}/${status}`,
      {},
      headers
    )
  }

  public static async addToBulk(ids: number[], token: string): Promise<void> {
    const headers = { Authorization: `Bearer ${token}` }
    await client.DoRequest("POST", `/balance-return/addToBulk`, ids, headers)
  }

  public static async removeFromBulk(id: number, token: string): Promise<void> {
    const headers = { Authorization: `Bearer ${token}` }
    await client.DoRequest(
      "DELETE",
      `/balance-return/removeFromBulk/${id}`,
      {},
      headers
    )
  }
}
