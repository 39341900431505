import { BalanceReturn } from "../../entities/csg/BalanceReturn"
import { SearchParams, SearchResult } from "../../entities"
import UserStorage from "../../util/userStorage"
import { BalanceReturnRepository } from "../../repositories/csg/BalanceReturnRepository"

export class BalanceReturnService {
  public static async search(
    params: SearchParams
  ): Promise<SearchResult<BalanceReturn>> {
    const token = UserStorage.getToken()
    const result = await BalanceReturnRepository.search(params, token)
    return result
  }

  public static async csv(params: SearchParams): Promise<string> {
    const token = UserStorage.getToken()
    const result = await BalanceReturnRepository.csv(params, token)
    return result
  }

  public static async getBalance(): Promise<BalanceReturn> {
    const token = UserStorage.getToken()
    return await BalanceReturnRepository.getBalance(token)
  }

  public static async get(id: number): Promise<BalanceReturn> {
    const token = UserStorage.getToken()
    return await BalanceReturnRepository.get(id, token)
  }

  public static async updateBalanceStatus(
    id: number,
    status: string
  ): Promise<void> {
    const token = UserStorage.getToken()
    await BalanceReturnRepository.update(id, status, token)
  }

  public static async addToBulk(ids: number[]): Promise<boolean> {
    const token = UserStorage.getToken()
    await BalanceReturnRepository.addToBulk(ids, token)
    return true
  }

  public static async removeFromBulk(id: number): Promise<boolean> {
    const token = UserStorage.getToken()
    await BalanceReturnRepository.removeFromBulk(id, token)
    return true
  }
}
