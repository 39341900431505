import { useEffect, useState } from "react"
import {
  BalanceReturn,
  SearchFilter,
  SearchParams,
  SearchResultInfo,
} from "../../../../entities"
import { BalanceReturnService } from "../../../../services/csg/BalanceReturnService"
import { Button, Flex, Link as ChakraLink, useToast } from "@chakra-ui/react"
import { Link as RouterLink } from "react-router-dom"
import DataTable from "../../../../components/dataTable/DataTable"
import Formatter from "../../../../util/formatter"

interface BalanceReturnListProps {
  loading: boolean
  setLoading: (loading: boolean) => void
  reload: string
  setReload: (reload: string) => void
  filters: {
    createdAtFrom: string
    createdAtTo: string
    name: string
    email: string
    key: string
    status: string
  }
  canApprove: boolean
}

export default function BalanceReturnList({
  loading,
  setLoading,
  reload,
  setReload,
  filters,
}: BalanceReturnListProps) {
  const toast = useToast()

  const [balanceReturns, setBalanceReturns] = useState<BalanceReturn[]>([])
  const [resultInfo, setResultInfo] = useState(new SearchResultInfo())
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  useEffect(() => {
    if (reload === "list") load(1)
    if (reload === "csv") downloadCsv()
    // eslint-disable-next-line
  }, [reload])

  useEffect(() => {
    console.log("filters", filters)
    load(1)
    // eslint-disable-next-line
  }, [])

  const getFilters = (): SearchFilter[] => {
    const searchFilters: SearchFilter[] = []

    if (filters.createdAtFrom) {
      searchFilters.push(
        new SearchFilter({
          key: "createdAtFrom",
          value: filters.createdAtFrom,
          operator: "eq",
        })
      )
    }

    if (filters.createdAtTo) {
      searchFilters.push(
        new SearchFilter({
          key: "createdAtTo",
          value: filters.createdAtTo,
          operator: "eq",
        })
      )
    }

    if (filters.status) {
      searchFilters.push(
        new SearchFilter({
          key: "status",
          value: filters.status,
          operator: "eq",
        })
      )
    }

    if (filters.name) {
      searchFilters.push(
        new SearchFilter({ key: "name", value: filters.name, operator: "like" })
      )
    }

    if (filters.email) {
      searchFilters.push(
        new SearchFilter({ key: "email", value: filters.email, operator: "eq" })
      )
    }

    if (filters.key) {
      searchFilters.push(
        new SearchFilter({ key: "key", value: filters.key, operator: "like" })
      )
    }

    return searchFilters
  }

  const load = async (page = 1) => {
    const filters = getFilters()
    const searchParams = new SearchParams(filters, page, 50)

    setLoading(true)
    BalanceReturnService.search(searchParams)
      .then((result) => {
        setBalanceReturns(result.data)
        setResultInfo(result.info)
      })
      .finally(() => {
        setLoading(false)
        setReload("")
      })
  }

  const downloadCsv = () => {
    const filters = getFilters()
    const searchParams = new SearchParams(filters, 1, 999999999)

    setLoading(true)
    BalanceReturnService.csv(searchParams)
      .then((href: string) => {
        const tempLink = document.createElement("a")
        tempLink.href = href
        tempLink.setAttribute("download", "balance-returns.csv")
        tempLink.click()
      })
      .finally(() => {
        setLoading(false)
        setReload("")
      })
  }

  const addToBulk = () => {
    const selected: number[] = []
    for (const id of selectedIds) {
      const balanceReturn = balanceReturns.find((br) => br.id === id)
      if (!balanceReturn) continue
      if (balanceReturn.status !== "open") continue
      if (balanceReturn.bulk) continue

      selected.push(id)
    }

    if (selected.length === 0) {
      toast({
        title: "Escolha pelo menos um item",
        position: "top",
        duration: 2000,
        status: "error",
      })
      return
    }

    setLoading(true)
    BalanceReturnService.addToBulk(selected)
      .then(() => {
        load()
        setReload("list-bulk")
      })
      .finally(() => setLoading(false))
  }

  // const approve = (id: number) => {
  //   const resp = window.confirm(
  //     "Deseja confirmar o envio da solicitação de resgate?"
  //   )
  //   if (!resp) return

  //   BalanceReturnService.updateBalanceStatus(id, "complete").then(() =>
  //     load(resultInfo.page)
  //   )
  // }

  // const renderActions = (params: any) => {
  //   return (
  //     <div>
  //       {params.status === "open" && canApprove && (
  //         <Button
  //           bg="#E7ECFF"
  //           color="#00335B"
  //           transition="0.2s"
  //           _hover={{ bg: "#114B8F", color: "#fff" }}
  //           onClick={() => approve(params.id)}
  //         >
  //           Confirmar Resgate
  //         </Button>
  //       )}
  //     </div>
  //   )
  // }

  const renderName = (params: any) => {
    if (params.user?.id)
      return (
        <ChakraLink
          as={RouterLink}
          to={`/customer/${params.user?.id}`}
          target="_blank"
        >
          {params.user.firstname}
        </ChakraLink>
      )
    else return params.name
  }

  const renderStatus = (params: any) => {
    return Formatter.formatStatus(params.status)
  }

  const renderType = (params: any) => {
    if (params.type === "phone") return "Telefone"
    if (params.type === "email") return "E-mail"
    if (params.type === "cpf") return "CPF"
    if (params.type === "cpnj") return "CNPJ"
    if (params.type === "random") return "Aleatório"
    return params.type
  }

  const renderAmount = (params: any) => {
    return Formatter.formatCurrency(params.amount)
  }

  const renderTax = (params: any) => {
    return Formatter.formatCurrency(params.tax)
  }

  const renderTotal = (params: any) => {
    return Formatter.formatCurrency(params.amount - params.tax)
  }

  const renderCreatedAt = (params: any) => {
    return Formatter.formatDateTime(new Date(params.createdAt))
  }

  const columns = [
    { renderCell: renderName, headerName: "Nome", mWidth: "50%" },
    { field: "user.email", headerName: "E-mail", mWidth: "50%" },
    { renderCell: renderType, headerName: "Tipo", mWidth: "20%" },
    { field: "key", headerName: "Chave", mWidth: "80%" },
    { renderCell: renderAmount, headerName: "Valor", mWidth: "30%" },
    { renderCell: renderTax, headerName: "Taxa", mWidth: "30%" },
    { renderCell: renderTotal, headerName: "Total", mWidth: "30%" },
    {
      renderCell: renderCreatedAt,
      headerName: "Data do pedido",
      mWidth: "100%",
    },
    { renderCell: renderStatus, headerName: "Status", mWidth: "35%" },
    // {
    //   headerName: "Ações",
    //   renderCell: renderActions,
    //   mWidth: "60%",
    //   mAlign: "end",
    //   mDisplay: "none",
    // },
  ]

  return (
    <>
      <Flex w="100%">
        <DataTable
          checkboxSelection={true}
          rows={balanceReturns}
          columns={columns}
          pagination={resultInfo}
          onPageChange={(page: number) => {
            load(page)
            setSelectedIds([])
          }}
          loading={loading}
          selectedIds={setSelectedIds}
        />
      </Flex>
      <Flex w="100%" direction="column" alignItems="flex-end">
        <Button
          w="auto"
          minW="150px"
          size="md"
          borderRadius="0.375rem"
          bg="#00335B"
          fontFamily="Raleway"
          fontSize="0.875rem"
          fontWeight="600"
          color="#fff"
          _hover={{ bg: "#00335B" }}
          onClick={addToBulk}
        >
          Adicionar ao lote
        </Button>
      </Flex>
    </>
  )
}
