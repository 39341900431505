import React, { useEffect, useState } from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import {
  Button,
  Flex,
  FormControl,
  Input,
  Select,
  Link as ChakraLink,
  Text,
} from "@chakra-ui/react"
import { GrView } from "react-icons/gr"
import {
  Charge,
  SearchFilter,
  SearchParams,
  SearchResultInfo,
} from "../../../../entities"
import { ChargeService } from "../../../../services"
import Layout from "../../../../components/layout/Layout"
import Breadcrumbs from "../../../../components/layout/breadcrumbs/Breadcrumbs"
import DataTable from "../../../../components/dataTable/DataTable"
import { BsDownload } from "react-icons/bs"
import Loader from "../../../../components/Loader/Loader"
import { BsCash } from "react-icons/bs"
import Formatter from "../../../../util/formatter"

export default function ChargeListPage() {
  const today = new Date()
  const day = ("0" + today.getDate()).slice(-2)
  const month = ("0" + (today.getMonth() + 1)).slice(-2)

  const [charges, setCharges] = useState<Charge[]>([])
  const [resultInfo, setResultInfo] = useState(
    new SearchResultInfo({ pageSize: 20 })
  )
  const [loading, setLoading] = useState(false)
  const [licensePlate, setLicensePlate] = useState("")
  const [occurrenceFrom, setOccurrenceFrom] = useState(
    today.getFullYear() + "-" + month + "-" + day
  )
  const [occurrenceTo, setOccurrenceTo] = useState("")
  const [status, setStatus] = useState("")
  const [paymentMethod, setPaymentMethod] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")

  useEffect(() => {
    load()
    // eslint-disable-next-line
  }, [])

  const getFilters = (): SearchFilter[] => {
    const filters: SearchFilter[] = []
    if (licensePlate) {
      filters.push(
        new SearchFilter({
          key: "licensePlate2",
          value: licensePlate,
          operator: "eq",
        })
      )
    }

    if (occurrenceFrom) {
      filters.push(
        new SearchFilter({
          key: "occurrenceFrom",
          value: occurrenceFrom,
          operator: "eq",
        })
      )
    }

    if (occurrenceTo) {
      filters.push(
        new SearchFilter({
          key: "occurrenceTo",
          value: occurrenceTo,
          operator: "eq",
        })
      )
    }

    if (status) {
      filters.push(
        new SearchFilter({ key: "status", value: status, operator: "eq" })
      )
    }

    if (paymentMethod) {
      filters.push(
        new SearchFilter({
          key: "paymentMethod",
          value: paymentMethod,
          operator: "eq",
        })
      )
    }

    if (name) {
      filters.push(
        new SearchFilter({ key: "name", value: name, operator: "like" })
      )
    }

    if (email) {
      filters.push(
        new SearchFilter({ key: "email", value: email, operator: "eq" })
      )
    }

    return filters
  }

  const load = async (page = 1) => {
    const filters = getFilters()
    const searchParams = new SearchParams(filters, page, 10)

    setLoading(true)
    ChargeService.search(searchParams)
      .then((result) => {
        setCharges(result.data)
        setResultInfo(result.info)
      })
      .finally(() => setLoading(false))
  }

  const downloadCsv = () => {
    const filters = getFilters()
    const searchParams = new SearchParams(filters, 1, 999999999)

    setLoading(true)
    ChargeService.csv(searchParams)
      .then((href: string) => {
        const tempLink = document.createElement("a")
        tempLink.href = href
        tempLink.setAttribute("download", "charges.csv")
        tempLink.click()
      })
      .finally(() => setLoading(false))
  }

  const renderActions = (params: any) => {
    return (
      <div>
        <ChakraLink as={ReactRouterLink} to={`/charge/${params.id}`}>
          <Button
            bg="#E7ECFF"
            color="#00335B"
            transition="0.2s"
            _hover={{ bg: "#114B8F", color: "#fff" }}
          >
            <GrView />
          </Button>
        </ChakraLink>
      </div>
    )
  }

  const renderCustomer = (params: any) => {
    return (
      <ChakraLink as={ReactRouterLink} to={`/customer/${params.user?.id}`}>
        {params.user?.firstname}
      </ChakraLink>
    )
  }

  const rows = charges.map((charge: Charge) => {
    const total = charge.rateAmount - charge.discount + charge.fee

    return {
      ...charge,
      totalFormated: Formatter.formatCurrency(total),
    }
  })

  const columns = [
    { renderCell: renderCustomer, headerName: "Cliente", mWidth: "100%" },
    {
      field: "datetimeOccurrenceFormated",
      headerName: "Data de Ocorrência",
      mWidth: "55%",
    },
    { field: "licensePlate", headerName: "Placa", mWidth: "40%" },
    { field: "rateAmountFormated", headerName: "Tarifa", mWidth: "45%" },
    { field: "discountFormated", headerName: "Desconto", mWidth: "45%" },
    { field: "discountDufFormated", headerName: "DUF", mWidth: "45%" },
    { field: "discountDbtFormated", headerName: "DBT", mWidth: "45%" },
    { field: "feeFormated", headerName: "Acréscimo", mWidth: "45%" },
    { field: "totalFormated", headerName: "Total", mWidth: "45%" },
    { field: "plazaDescription", headerName: "Praça", mWidth: "45%" },
    { field: "statusFormated", headerName: "Status", mWidth: "30%" },
    {
      headerName: "Ações",
      renderCell: renderActions,
      mWidth: "65%",
      mAlign: "end",
      mDisplay: "none",
    },
  ]

  return (
    <Layout>
      {loading && <Loader />}
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        mb="3rem"
      >
        <Flex direction="column">
          <Breadcrumbs
            items={[
              { label: "Dashboard", link: "/" },
              { label: "Cobranças", link: "/charge" },
            ]}
            additionalHeaderInfo={
              <>
                {/* <Link to="/charge/0"><button><AiOutlinePlus /></button></Link> */}
              </>
            }
          />
          <Flex gap="0.75rem" alignItems="center">
            <Flex fontSize="2rem" fontWeight="700" color="#114B8F">
              <BsCash />
            </Flex>
            <Text
              fontSize={{
                base: "1.5rem",
                sm: "1.75rem",
                md: "2rem",
                lg: "2.25rem",
              }}
              fontWeight="700"
              color="#114B8F"
              fontFamily="DM Sans"
            >
              Cobranças
            </Text>
          </Flex>
        </Flex>
        <Flex
          w="auto"
          ml="auto"
          alignSelf="flex-end"
          justifyContent={{
            base: "flex-end",
            sm: "flex-end",
            md: "flex-end",
            lg: "flex-end",
          }}
          gap="1.25rem"
          alignItems="flex-end"
          direction={{ base: "column", sm: "row", md: "row", lg: "row" }}
        >
          <FormControl
            w="100%"
            maxW={{ base: "100%", sm: "100%", md: "100%", lg: "648px" }}
            isInvalid={false}
            display="flex"
            justifyContent="flex-end"
            gap="0.5rem"
            flexWrap="wrap"
          >
            <Input
              maxW={{ base: "100%", sm: "158px", md: "158px", lg: "158px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD" }}
              placeholder="Período inicial"
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="date"
              name="from"
              value={occurrenceFrom}
              onChange={(e) => setOccurrenceFrom(e.target.value)}
            />
            <Input
              maxW={{ base: "100%", sm: "158px", md: "158px", lg: "158px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD" }}
              placeholder="Período final"
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="date"
              name="to"
              value={occurrenceTo}
              onChange={(e) => setOccurrenceTo(e.target.value)}
            />
            <Input
              maxW={{ base: "100%", sm: "154px", md: "154px", lg: "154px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD", textTransform: "capitalize" }}
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="text"
              name="licensePlate"
              placeholder="Placa"
              textTransform="uppercase"
              value={licensePlate}
              onChange={(e) => setLicensePlate(e.target.value.toUpperCase())}
            />
            <Select
              maxW={{ base: "100%", sm: "120px", md: "120px", lg: "120px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD" }}
              placeholder="Status"
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="">Todos</option>
              <option value="open">Aberto</option>
              <option value="paid">Pago</option>
            </Select>
            <Select
              maxW={{ base: "100%", sm: "204px", md: "204px", lg: "204px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD" }}
              placeholder="Método de pagamento"
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <option value="">Todos</option>
              <option value="credit-card">Cartão de Crédito</option>
              <option value="pix">Pix</option>
              <option value="cash">Dinheiro</option>
              <option value="balance">Crédito</option>
              <option value="vvp">Vale</option>
              <option value="credit-card-pinpad">
                Cartão de Crédito Totem
              </option>
              <option value="debit-card-pinpad">Cartão de Débito Totem</option>
            </Select>
            <Input
              maxW={{ base: "100%", sm: "204px", md: "204px", lg: "204px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD" }}
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="email"
              name="email"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              maxW={{ base: "100%", sm: "204px", md: "204px", lg: "204px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD", textTransform: "capitalize" }}
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="text"
              name="name"
              placeholder="Nome"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <Flex gap="1rem">
            <Button
              minW="95px"
              size="md"
              borderRadius="0.375rem"
              bg="#00335B"
              fontFamily="Raleway"
              fontSize="0.875rem"
              fontWeight="600"
              color="#fff"
              _hover={{ bg: "#00335B" }}
              onClick={() => load(1)}
            >
              Buscar
            </Button>
            <Button
              minW="95px"
              size="md"
              borderRadius="0.375rem"
              bg="#F7F8F9"
              fontFamily="Raleway"
              fontSize="0.875rem"
              fontWeight="600"
              color="#1A202C"
              _hover={{ bg: "#F7F8F9" }}
              onClick={() => downloadCsv()}
              display="flex"
              gap="0.5rem"
              alignItems="center"
              justifyContent="center"
            >
              <BsDownload />
              CSV
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <DataTable
        checkboxSelection={false}
        rows={rows}
        columns={columns}
        pagination={resultInfo}
        onPageChange={(page: number) => load(page)}
        loading={loading}
      />
    </Layout>
  )
}
