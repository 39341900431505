import { User } from "../user"
import { BalanceReturnBulk } from "./BalanceReturnBulk"

export class BalanceReturn {
  public id: number
  public fullName: string
  public email: string
  public bank: string
  public branch: number
  public account: number
  public type: string
  public key: string
  public amount: number
  public tax: number
  public document: string
  public status: string
  public errorMessage: string
  public createdAt: Date | null
  public updatedAt: Date
  public approvedAt: Date
  public approvedBy: User | null
  public bulk: BalanceReturnBulk | null
  public user: User | null

  constructor(data: any) {
    this.id = data.id
    this.fullName = data.fullName
    this.email = data.email
    this.bank = data.bank
    this.branch = data.branch
    this.account = data.account
    this.type = data.type
    this.key = data.key
    this.amount = parseInt(data.amount)
    this.tax = parseInt(data.tax)
    this.document = data.document
    this.status = data.status
    this.errorMessage = data.errorMessage || ""
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
    this.approvedAt = data.approvedAt
    this.approvedBy = data.approvedBy ? new User(data.approvedBy) : null
    this.bulk = data.bulk ? new BalanceReturnBulk(data.bulk) : null
    this.user = data.user ? new User(data.user) : null
  }
}
