import { BalanceReturnBulk, SearchParams, SearchResult } from "../../entities"
import UserStorage from "../../util/userStorage"
import { BalanceReturnBulkRepository } from "../../repositories/csg/BalanceReturnBulkRepository"

export class BalanceReturnBulkService {
  public static async search(
    params: SearchParams
  ): Promise<SearchResult<BalanceReturnBulk>> {
    const token = UserStorage.getToken()
    const result = await BalanceReturnBulkRepository.search(params, token)
    return result
  }

  public static async csv(params: SearchParams): Promise<string> {
    const token = UserStorage.getToken()
    const result = await BalanceReturnBulkRepository.csv(params, token)
    return result
  }

  public static async report(id: number): Promise<string> {
    const token = UserStorage.getToken()
    const result = await BalanceReturnBulkRepository.report(id, token)
    return result
  }

  public static async get(id: number): Promise<BalanceReturnBulk> {
    const token = UserStorage.getToken()
    return await BalanceReturnBulkRepository.get(id, token)
  }

  public static async send(id: number): Promise<boolean> {
    const token = UserStorage.getToken()
    await BalanceReturnBulkRepository.send(id, token)
    return true
  }
}
