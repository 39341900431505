import React, { useEffect, useState } from "react"
import Layout from "../../../../../components/layout/Layout"
import DataTable from "../../../../../components/dataTable/DataTable"
import {
  Button,
  Flex,
  FormControl,
  Input,
  Link as ChakraLink,
  Text,
  useToast,
  Tooltip,
} from "@chakra-ui/react"
import { Link as ReactRouterLink } from "react-router-dom"
import { BalanceReturn, BalanceReturnBulk } from "../../../../../entities"
import {
  BalanceReturnBulkService,
  BalanceReturnService,
} from "../../../../../services"
import Breadcrumbs from "../../../../../components/layout/breadcrumbs/Breadcrumbs"
import Loader from "../../../../../components/Loader/Loader"
import { BsCoin, BsDownload, BsFillQuestionCircleFill } from "react-icons/bs"
import { useParams } from "react-router-dom"
import Formatter from "../../../../../util/formatter"
import { GrTrash } from "react-icons/gr"

export default function BalanceReturnBulkDetailPage() {
  const toast = useToast()
  const params = useParams()

  const [bulk, setBulk] = useState<BalanceReturnBulk>(new BalanceReturnBulk())
  const [balanceReturns, setBalanceReturns] = useState<BalanceReturn[]>([])
  const [loading, setLoading] = useState(false)

  const [name, setName] = useState("")
  const [key, setKey] = useState("")
  const [email, setEmail] = useState("")

  useEffect(() => {
    if (params.id) {
      const id = parseInt(params.id)
      load(id)
    }
    // eslint-disable-next-line
  }, [params.id])

  const load = async (id: number) => {
    setLoading(true)
    BalanceReturnBulkService.get(id)
      .then((bulk) => {
        setBulk(bulk)
        setBalanceReturns(bulk.balanceReturns)
      })
      .finally(() => setLoading(false))
  }

  const filter = () => {
    const newBalanceReturns = [...bulk.balanceReturns]
    console.log("name2", name)
    const filtered = newBalanceReturns.filter(
      (balanceReturn: BalanceReturn) => {
        console.log(balanceReturn)
        console.log("name", name)
        if (name) {
          const firstname = balanceReturn.user?.firstname || ""
          const lastname = balanceReturn.user?.lastname || ""
          const fullname = firstname + " " + lastname
          if (!fullname.toLowerCase().includes(name.toLowerCase())) return false
        }

        if (email) {
          if (
            !balanceReturn.email
              .toLowerCase()
              .includes(email.toLocaleLowerCase())
          )
            return false
        }

        if (key) {
          if (
            !balanceReturn.key.toLowerCase().includes(key.toLocaleLowerCase())
          )
            return false
        }

        return true
      }
    )

    setBalanceReturns(filtered)
  }

  const approve = () => {
    const resp = window.confirm("Deseja confirmar o envio do lote?")
    if (!resp) return

    setLoading(true)
    BalanceReturnBulkService.send(bulk.id)
      .then((success: boolean) => {
        if (success) {
          toast({
            title: "Lote enviado com sucesso.",
            duration: 2000,
            position: "top",
            status: "success",
          })
        } else {
          toast({
            title: "Falha ao tentar enviar o lote",
            duration: 2000,
            position: "top",
            status: "error",
          })
        }
        load(bulk.id)
      })
      .finally(() => setLoading(false))
  }

  const removeFromBulk = (id: number) => {
    setLoading(true)
    BalanceReturnService.removeFromBulk(id)
      .then(() => {
        if (params.id) {
          const id = parseInt(params.id)
          load(id)
        }
      })
      .catch((error) => setLoading(false))
  }

  const downloadCsv = () => {
    setLoading(true)

    BalanceReturnBulkService.report(bulk.id)
      .then((href: string) => {
        const tempLink = document.createElement("a")
        tempLink.href = href
        tempLink.setAttribute("download", `bulk-report-${bulk.name}.csv`)
        tempLink.click()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const renderName = (params: any) => {
    if (params.user?.id)
      return (
        <ChakraLink
          as={ReactRouterLink}
          to={`/customer/${params.user?.id}`}
          target="_blank"
        >
          {params.user.firstname}
        </ChakraLink>
      )
    else return params.name
  }

  const renderStatus = (params: any) => {
    const tooltip =
      params.status === "fail" ? (
        <Tooltip label={params?.errorMessage} fontSize="sm">
          <Text as="span">
            <BsFillQuestionCircleFill />
          </Text>
        </Tooltip>
      ) : (
        <></>
      )
    return (
      <Flex alignItems="center" gap="0.3rem">
        {Formatter.formatStatus(params.status)} {tooltip}
      </Flex>
    )
  }

  const renderType = (params: any) => {
    if (params.type === "phone") return "Telefone"
    if (params.type === "email") return "E-mail"
    if (params.type === "cpf") return "CPF"
    if (params.type === "cpnj") return "CNPJ"
    if (params.type === "random") return "Aleatório"
    return params.type
  }

  const renderAmount = (params: any) => {
    return Formatter.formatCurrency(params.amount)
  }

  const renderTax = (params: any) => {
    return Formatter.formatCurrency(params.tax)
  }

  const renderTotal = (params: any) => {
    return Formatter.formatCurrency(params.amount - params.tax)
  }

  const renderCreatedAt = (params: any) => {
    return Formatter.formatDateTime(new Date(params.createdAt))
  }

  const renderActions = (params: any) => {
    return (
      <div>
        {bulk.status === "open" && (
          <Button
            bg="#E7ECFF"
            color="#00335B"
            transition="0.2s"
            _hover={{ bg: "#114B8F", color: "#fff" }}
            onClick={() => removeFromBulk(params.id)}
          >
            <GrTrash />
          </Button>
        )}
      </div>
    )
  }

  const columns = [
    { renderCell: renderName, headerName: "Nome", mWidth: "50%" },
    { field: "user.email", headerName: "E-mail", mWidth: "50%" },
    { renderCell: renderType, headerName: "Tipo", mWidth: "20%" },
    { field: "key", headerName: "Chave", mWidth: "80%" },
    { renderCell: renderAmount, headerName: "Valor", mWidth: "30%" },
    { renderCell: renderTax, headerName: "Taxa", mWidth: "30%" },
    { renderCell: renderTotal, headerName: "Total", mWidth: "30%" },
    {
      renderCell: renderCreatedAt,
      headerName: "Data do pedido",
      mWidth: "100%",
    },
    { renderCell: renderStatus, headerName: "Status", mWidth: "35%" },
    { renderCell: renderActions, headerName: "Ações", mWidth: "35%" },
  ]

  return (
    <Layout>
      {loading && <Loader />}
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        mb="3rem"
      >
        <Flex direction="column">
          <Breadcrumbs
            items={[
              { label: "Dashboard", link: "/" },
              { label: "Solicitações de Resgate", link: "/balance-return" },
              {
                label: "Detalhes do Lote",
                link: `/balance-return/bulk/${params.id}`,
              },
            ]}
            additionalHeaderInfo={
              <>
                {/* <Link to="/id/0"><button><AiOutlinePlus /></button></Link> */}
              </>
            }
          />
          <Flex gap="0.75rem" alignItems="center">
            <Flex fontSize="2rem" fontWeight="700" color="#114B8F">
              <BsCoin />
            </Flex>
            <Text
              fontSize={{
                base: "1.5rem",
                sm: "1.75rem",
                md: "2rem",
                lg: "2.25rem",
              }}
              fontWeight="700"
              color="#114B8F"
              fontFamily="DM Sans"
            >
              Lote {bulk.name}
            </Text>
          </Flex>
        </Flex>
        <Flex
          w="auto"
          ml="auto"
          alignSelf="flex-end"
          justifyContent={{
            base: "flex-end",
            sm: "flex-end",
            md: "flex-end",
            lg: "flex-end",
          }}
          gap="1.25rem"
          alignItems="flex-end"
          flexDirection={{
            base: "column",
            sm: "column",
            md: "column",
            lg: "row",
          }}
        >
          <FormControl
            w="100%"
            maxW={{ base: "100%", sm: "100%", md: "100%", lg: "648px" }}
            isInvalid={false}
            display="flex"
            justifyContent="flex-end"
            gap="0.5rem"
            flexWrap="wrap"
          >
            <Input
              maxW={{ base: "100%", sm: "204px", md: "204px", lg: "204px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              placeholder="E-mail"
              _placeholder={{ color: "#A2ACBD" }}
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              maxW={{ base: "100%", sm: "204px", md: "204px", lg: "204px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              placeholder="Chave"
              _placeholder={{ color: "#A2ACBD" }}
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="text"
              name="keyName"
              value={key}
              onChange={(e) => setKey(e.target.value)}
            />
            <Input
              maxW={{ base: "100%", sm: "204px", md: "204px", lg: "204px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              placeholder="Nome"
              _placeholder={{ color: "#A2ACBD" }}
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
          <Flex gap="1rem">
            <Button
              minW="95px"
              size="md"
              borderRadius="0.375rem"
              bg="#00335B"
              fontFamily="Raleway"
              fontSize="0.875rem"
              fontWeight="600"
              color="#fff"
              _hover={{ bg: "#00335B" }}
              onClick={() => filter()}
            >
              Buscar
            </Button>
            <Button
              minW="95px"
              size="md"
              borderRadius="0.375rem"
              bg="#F7F8F9"
              fontFamily="Raleway"
              fontSize="0.875rem"
              fontWeight="600"
              color="#1A202C"
              _hover={{ bg: "#F7F8F9" }}
              onClick={() => downloadCsv()}
              display="flex"
              gap="0.5rem"
              alignItems="center"
              justifyContent="center"
            >
              <BsDownload />
              CSV
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        w="100%"
        mb="2rem"
        gap={{ base: "3rem", sm: "3rem", md: "4rem", lg: "4rem" }}
        flexWrap="wrap"
      >
        <Flex direction="column">
          <Text
            fontFamily="DM Sans"
            fontWeight="500"
            fontSize="0.75rem"
            textTransform="uppercase"
            color="#8E99AB"
          >
            DATA
          </Text>
          <Text
            fontFamily="DM Sans"
            fontWeight="700"
            fontSize="1.25rem"
            color="#2D3748"
          >
            {Formatter.formatDate(bulk.createdAt)}
          </Text>
        </Flex>
        <Flex direction="column">
          <Text
            fontFamily="DM Sans"
            fontWeight="500"
            fontSize="0.75rem"
            color="#8E99AB"
            textTransform="uppercase"
          >
            Valor total do lote
          </Text>
          <Text
            fontFamily="DM Sans"
            fontWeight="700"
            fontSize="1.25rem"
            color="#2D3748"
          >
            {Formatter.formatCurrency(bulk.total)}
          </Text>
        </Flex>
        <Flex direction="column">
          <Text
            fontFamily="DM Sans"
            fontWeight="500"
            fontSize="0.75rem"
            color="#8E99AB"
            textTransform="uppercase"
          >
            STATUS
          </Text>
          <Text
            fontFamily="DM Sans"
            fontWeight="700"
            fontSize="1.25rem"
            color="#2D3748"
          >
            {Formatter.formatStatus(bulk.status)}
          </Text>
        </Flex>
      </Flex>
      <DataTable
        checkboxSelection={false}
        rows={balanceReturns}
        columns={columns}
        loading={loading}
      />
      <Flex
        mt={{ base: "2rem", sm: "2rem", md: "2rem", lg: "1rem" }}
        w="100%"
        justifyContent="flex-end"
        alignItems="center"
        gap={{ base: "1rem", sm: "3rem", md: "3rem", lg: "3rem" }}
        flexWrap="wrap"
      >
        <Flex direction="column">
          <Text
            fontFamily="DM Sans"
            fontWeight="500"
            fontSize="0.75rem"
            textTransform="uppercase"
            color="#8E99AB"
          >
            total do lote
          </Text>
          <Text
            fontFamily="DM Sans"
            fontWeight="700"
            fontSize="1.38rem"
            color="#2B28A3"
          >
            {Formatter.formatCurrency(bulk.total)}
          </Text>
        </Flex>
        {bulk.status === "open" && (
          <Button
            w="auto"
            minW="150px"
            size="md"
            borderRadius="0.375rem"
            bg="#00335B"
            fontFamily="Raleway"
            fontSize="0.875rem"
            fontWeight="600"
            color="#fff"
            _hover={{ bg: "#00335B" }}
            onClick={approve}
          >
            Enviar lote
          </Button>
        )}
      </Flex>
    </Layout>
  )
}
