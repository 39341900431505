import React, { useEffect, useState } from "react"
import Layout from "../../../components/layout/Layout"
import { Flex, Text } from "@chakra-ui/react"
import { Dashboard } from "../../../entities/csg/Dashboard"
import { DashboardService } from "../../../services/csg/DashboardService"
import Loader from "../../../components/Loader/Loader"
import { useNavigate } from "react-router-dom"
import UserStorage from "../../../util/userStorage"
import Breadcrumbs from "../../../components/layout/breadcrumbs/Breadcrumbs"
import {
  BsCalendar2DateFill,
  BsCalendar4Week,
  BsHouseDoorFill,
  BsPersonFill,
  BsReplyAllFill,
  BsReplyFill,
} from "react-icons/bs"
import { PiVaultFill } from "react-icons/pi"
import DataTable from "../../../components/dataTable/DataTable"
import "./DashboardPage.scss"
import { UserService } from "../../../services"
import Formatter from "../../../util/formatter"
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts"

export default function DashboardPage() {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [dashboardData, setDashboardData] = useState<Dashboard>(new Dashboard())

  useEffect(() => {
    UserService.isValidUser().then((isValid) => {
      if (!isValid) {
        UserStorage.logout()
        navigate("/login")
        return
      }
      load()
    })
    // eslint-disable-next-line
  }, [])

  const load = () => {
    setIsLoading(true)
    DashboardService.get()
      .then((dash) => {
        console.log(dash)
        setDashboardData(dash)
      })
      .finally(() => setIsLoading(false))
  }

  const renderToday = (params: any) => {
    return Formatter.formatCurrency(params.today)
  }

  const renderD1 = (params: any) => {
    return Formatter.formatCurrency(params.d1)
  }

  const renderD2 = (params: any) => {
    return Formatter.formatCurrency(params.d2)
  }

  const renderD3 = (params: any) => {
    return Formatter.formatCurrency(params.d3)
  }

  const renderD4 = (params: any) => {
    return Formatter.formatCurrency(params.d4)
  }

  const renderD5 = (params: any) => {
    return Formatter.formatCurrency(params.d5)
  }

  const renderD6 = (params: any) => {
    return Formatter.formatCurrency(params.d6)
  }

  const renderD15 = (params: any) => {
    return Formatter.formatCurrency(params.d15)
  }

  const renderMonth = (params: any) => {
    return Formatter.formatCurrency(params.month)
  }

  const renderM1 = (params: any) => {
    return Formatter.formatCurrency(params.m1)
  }

  const renderM2 = (params: any) => {
    return Formatter.formatCurrency(params.m2)
  }

  const columns = [
    { field: "type", headerName: "", mWidth: "18%" },
    { renderCell: renderToday, headerName: "Dia Atual", mWidth: "18%" },
    { renderCell: renderD1, headerName: "D-1", mWidth: "18%" },
    { renderCell: renderD2, headerName: "D-2", mWidth: "18%" },
    { renderCell: renderD3, headerName: "D-3", mWidth: "18%" },
    { renderCell: renderD4, headerName: "D-4", mWidth: "18%" },
    { renderCell: renderD5, headerName: "D-5", mWidth: "18%" },
    { renderCell: renderD6, headerName: "D-6", mWidth: "18%" },
    { renderCell: renderD15, headerName: "D-15", mWidth: "18%" },
    { renderCell: renderMonth, headerName: "Mês Atual", mWidth: "18%" },
    { renderCell: renderM1, headerName: "Mês-1", mWidth: "18%" },
    { renderCell: renderM2, headerName: "Mês-2", mWidth: "18%" },
  ]

  const renderTodayQty = (params: any) => {
    return Formatter.formatNumber(params.today)
  }

  const renderD1Qty = (params: any) => {
    return Formatter.formatNumber(params.d1)
  }

  const renderD2Qty = (params: any) => {
    return Formatter.formatNumber(params.d2)
  }

  const renderD3Qty = (params: any) => {
    return Formatter.formatNumber(params.d3)
  }

  const renderD4Qty = (params: any) => {
    return Formatter.formatNumber(params.d4)
  }

  const renderD5Qty = (params: any) => {
    return Formatter.formatNumber(params.d5)
  }

  const renderD6Qty = (params: any) => {
    return Formatter.formatNumber(params.d6)
  }

  const renderD15Qty = (params: any) => {
    return Formatter.formatNumber(params.d15)
  }

  const renderMonthQty = (params: any) => {
    return Formatter.formatNumber(params.month)
  }

  const renderM1Qty = (params: any) => {
    return Formatter.formatNumber(params.m1)
  }

  const renderM2Qty = (params: any) => {
    return Formatter.formatNumber(params.m2)
  }

  const columnsQty = [
    { field: "type", headerName: "", mWidth: "18%" },
    { renderCell: renderTodayQty, headerName: "Dia Atual", mWidth: "18%" },
    { renderCell: renderD1Qty, headerName: "D-1", mWidth: "18%" },
    { renderCell: renderD2Qty, headerName: "D-2", mWidth: "18%" },
    { renderCell: renderD3Qty, headerName: "D-3", mWidth: "18%" },
    { renderCell: renderD4Qty, headerName: "D-4", mWidth: "18%" },
    { renderCell: renderD5Qty, headerName: "D-5", mWidth: "18%" },
    { renderCell: renderD6Qty, headerName: "D-6", mWidth: "18%" },
    { renderCell: renderD15Qty, headerName: "D-15", mWidth: "18%" },
    { renderCell: renderMonthQty, headerName: "Mês Atual", mWidth: "18%" },
    { renderCell: renderM1Qty, headerName: "Mês-1", mWidth: "18%" },
    { renderCell: renderM2Qty, headerName: "Mês-2", mWidth: "18%" },
  ]

  //SALDO, CRÉDITO, DÉBITO, PIX, DINHEIRO, VVP, TOTEM CRÉDITO, TOTEM DÉBITO, TOTEM PIX, TOTAL
  //EM ABERTO, TOTAL
  //PAGAS, EM ABERTO, TOTAL

  return (
    <Layout isHome={true}>
      {/* {isLoading ? (
        <Loader />
      ) : ( */}
      <Flex w="100%" flexDirection="column" gap="1rem">
        <Flex bg="#fff" borderRadius="1rem" p="1rem" direction="column">
          {isLoading && <Loader />}
          <Breadcrumbs items={[{ label: "Dashboard", link: "/" }]} />
          <Text
            fontSize="2.25rem"
            fontWeight="700"
            color="#114B8F"
            fontFamily="DM Sans"
            display="flex"
            gap="1rem"
            alignItems="center"
          >
            <BsHouseDoorFill />
            Dashboard
          </Text>
        </Flex>
        <Flex w="100%" flexWrap="wrap" gap="1rem" className="dashboard-icon-1">
          <Flex
            w="100%"
            maxW="377.7px"
            bg="#fff"
            borderRadius="1rem"
            p="1rem 2rem"
            justifyContent={{
              base: "center",
              sm: "flex-start",
              md: "flex-start",
              lg: "flex-start",
            }}
            gap="1rem"
            flexWrap={{
              base: "wrap",
              sm: "nowrap",
              md: "nowrap",
              lg: "nowrap",
            }}
          >
            <Flex
              bg="#E7ECFF"
              h="72px"
              w="72px"
              borderRadius="50%"
              justifyContent="center"
              alignItems="center"
              fontSize="2.5rem"
            >
              <BsPersonFill />
            </Flex>
            <Flex
              direction="column"
              alignItems={{
                base: "center",
                sm: "normal",
                md: "normal",
                lg: "normal",
              }}
            >
              <Text
                fontSize="0.875rem"
                fontWeight="700"
                color="#8E99AB"
                fontFamily="DM Sans"
              >
                Qtd. de cadastros atual
              </Text>
              <Text
                fontSize="2.38rem"
                fontWeight="700"
                color="#2D3748"
                fontFamily="DM Sans"
                textAlign={{
                  base: "center",
                  sm: "inherit",
                  md: "inherit",
                  lg: "inherit",
                }}
              >
                {Formatter.formatNumber(dashboardData.numberOfClients)}
              </Text>
            </Flex>
          </Flex>
          <Flex
            w="100%"
            maxW="377.7px"
            bg="#fff"
            borderRadius="1rem"
            p="1rem 2rem"
            justifyContent={{
              base: "center",
              sm: "flex-start",
              md: "flex-start",
              lg: "flex-start",
            }}
            gap="1rem"
            flexWrap={{
              base: "wrap",
              sm: "nowrap",
              md: "nowrap",
              lg: "nowrap",
            }}
          >
            <Flex
              bg="#E7ECFF"
              h="72px"
              w="72px"
              borderRadius="50%"
              justifyContent="center"
              alignItems="center"
              fontSize="2.5rem"
            >
              <BsCalendar2DateFill />
            </Flex>
            <Flex
              direction="column"
              alignItems={{
                base: "center",
                sm: "normal",
                md: "normal",
                lg: "normal",
              }}
            >
              <Text
                fontSize="0.875rem"
                fontWeight="700"
                color="#8E99AB"
                fontFamily="DM Sans"
              >
                Mês atual
              </Text>
              <Text
                fontSize="2.38rem"
                fontWeight="700"
                color="#2D3748"
                fontFamily="DM Sans"
                textAlign={{
                  base: "center",
                  sm: "inherit",
                  md: "inherit",
                  lg: "inherit",
                }}
              >
                {Formatter.formatNumber(dashboardData.numberOfClientsThisMonth)}
              </Text>
            </Flex>
          </Flex>
          <Flex
            w="100%"
            maxW="377.7px"
            bg="#fff"
            borderRadius="1rem"
            p="1rem 2rem"
            justifyContent={{
              base: "center",
              sm: "flex-start",
              md: "flex-start",
              lg: "flex-start",
            }}
            gap="1rem"
            flexWrap={{
              base: "wrap",
              sm: "nowrap",
              md: "nowrap",
              lg: "nowrap",
            }}
          >
            <Flex
              bg="#E7ECFF"
              h="72px"
              w="72px"
              borderRadius="50%"
              justifyContent="center"
              alignItems="center"
              fontSize="2.5rem"
            >
              <BsReplyFill />
            </Flex>
            <Flex
              direction="column"
              alignItems={{
                base: "center",
                sm: "normal",
                md: "normal",
                lg: "normal",
              }}
            >
              <Text
                fontSize="0.875rem"
                fontWeight="700"
                color="#8E99AB"
                fontFamily="DM Sans"
              >
                Mês Anterior
              </Text>
              <Text
                fontSize="2.38rem"
                fontWeight="700"
                color="#2D3748"
                fontFamily="DM Sans"
                textAlign={{
                  base: "center",
                  sm: "inherit",
                  md: "inherit",
                  lg: "inherit",
                }}
              >
                {Formatter.formatNumber(dashboardData.numberOfClientsLastMonth)}
              </Text>
            </Flex>
          </Flex>
          <Flex
            w="100%"
            maxW="377.7px"
            bg="#fff"
            borderRadius="1rem"
            p="1rem 2rem"
            justifyContent={{
              base: "center",
              sm: "flex-start",
              md: "flex-start",
              lg: "flex-start",
            }}
            gap="1rem"
            flexWrap={{
              base: "wrap",
              sm: "nowrap",
              md: "nowrap",
              lg: "nowrap",
            }}
          >
            <Flex
              bg="#E7ECFF"
              h="72px"
              w="72px"
              borderRadius="50%"
              justifyContent="center"
              alignItems="center"
              fontSize="2.5rem"
            >
              <BsReplyAllFill />
            </Flex>
            <Flex
              direction="column"
              alignItems={{
                base: "center",
                sm: "normal",
                md: "normal",
                lg: "normal",
              }}
            >
              <Text
                fontSize="0.875rem"
                fontWeight="700"
                color="#8E99AB"
                fontFamily="DM Sans"
              >
                Mês retrasado
              </Text>
              <Text
                fontSize="2.38rem"
                fontWeight="700"
                color="#2D3748"
                fontFamily="DM Sans"
                textAlign={{
                  base: "center",
                  sm: "inherit",
                  md: "inherit",
                  lg: "inherit",
                }}
              >
                {Formatter.formatNumber(
                  dashboardData.numberOfClientsTwoMonthAgo
                )}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex w="100%" flexWrap="wrap" gap="1rem" className="dashboard-icon-2">
          <Flex
            w="100%"
            maxW="509px"
            bg="#fff"
            borderRadius="1rem"
            p="1rem 2rem"
            justifyContent={{
              base: "center",
              sm: "flex-start",
              md: "flex-start",
              lg: "flex-start",
            }}
            gap="1rem"
            flexWrap={{
              base: "wrap",
              sm: "nowrap",
              md: "nowrap",
              lg: "nowrap",
            }}
          >
            <Flex
              bg="#E7ECFF"
              h="72px"
              w="72px"
              borderRadius="50%"
              justifyContent="center"
              alignItems="center"
              fontSize="2.5rem"
            >
              <PiVaultFill />
            </Flex>
            <Flex
              direction="column"
              alignItems={{
                base: "center",
                sm: "normal",
                md: "normal",
                lg: "normal",
              }}
            >
              <Text
                fontSize="0.875rem"
                fontWeight="700"
                color="#8E99AB"
                fontFamily="DM Sans"
              >
                Clientes com saldo em custódia
              </Text>
              <Text
                fontSize="2.38rem"
                fontWeight="700"
                color="#2D3748"
                fontFamily="DM Sans"
                textAlign={{
                  base: "center",
                  sm: "inherit",
                  md: "inherit",
                  lg: "inherit",
                }}
              >
                {Formatter.formatNumber(dashboardData.customersWithBalance)}
              </Text>
            </Flex>
            <Flex
              direction="column"
              alignItems={{
                base: "center",
                sm: "normal",
                md: "normal",
                lg: "normal",
              }}
            >
              <Text
                fontSize="0.875rem"
                fontWeight="700"
                color="#8E99AB"
                fontFamily="DM Sans"
              >
                Saldo atual em custódia
              </Text>
              <Text
                fontSize="2.38rem"
                fontWeight="700"
                color="#2D3748"
                fontFamily="DM Sans"
                textAlign={{
                  base: "center",
                  sm: "inherit",
                  md: "inherit",
                  lg: "inherit",
                }}
              >
                {Formatter.formatCurrency(dashboardData.custodyThisMonth)}
              </Text>
            </Flex>
          </Flex>

          <Flex
            w="100%"
            maxW="509px"
            bg="#fff"
            borderRadius="1rem"
            p="1rem 2rem"
            justifyContent={{
              base: "center",
              sm: "flex-start",
              md: "flex-start",
              lg: "flex-start",
            }}
            gap="1rem"
            flexWrap={{
              base: "wrap",
              sm: "nowrap",
              md: "nowrap",
              lg: "nowrap",
            }}
          >
            <Flex
              bg="#E7ECFF"
              h="72px"
              w="72px"
              borderRadius="50%"
              justifyContent="center"
              alignItems="center"
              fontSize="2.5rem"
            >
              <BsCalendar2DateFill />
            </Flex>
            <Flex
              direction="column"
              alignItems={{
                base: "center",
                sm: "normal",
                md: "normal",
                lg: "normal",
              }}
            >
              <Text
                fontSize="0.875rem"
                fontWeight="700"
                color="#8E99AB"
                fontFamily="DM Sans"
              >
                Custódia do mês anterior
              </Text>
              <Text
                fontSize="2.38rem"
                fontWeight="700"
                color="#2D3748"
                fontFamily="DM Sans"
                textAlign={{
                  base: "center",
                  sm: "inherit",
                  md: "inherit",
                  lg: "inherit",
                }}
              >
                {Formatter.formatCurrency(dashboardData.custodyLastMonth)}
              </Text>
            </Flex>
          </Flex>

          <Flex
            w="100%"
            maxW="509px"
            bg="#fff"
            borderRadius="1rem"
            p="1rem 2rem"
            justifyContent={{
              base: "center",
              sm: "flex-start",
              md: "flex-start",
              lg: "flex-start",
            }}
            gap="1rem"
            flexWrap={{
              base: "wrap",
              sm: "nowrap",
              md: "nowrap",
              lg: "nowrap",
            }}
          >
            <Flex
              bg="#E7ECFF"
              h="72px"
              w="72px"
              borderRadius="50%"
              justifyContent="center"
              alignItems="center"
              fontSize="2.5rem"
            >
              <BsReplyFill />
            </Flex>
            <Flex
              direction="column"
              alignItems={{
                base: "center",
                sm: "normal",
                md: "normal",
                lg: "normal",
              }}
            >
              <Text
                fontSize="0.875rem"
                fontWeight="700"
                color="#8E99AB"
                fontFamily="DM Sans"
              >
                Custódia do mês retrasado
              </Text>
              <Text
                fontSize="2.38rem"
                fontWeight="700"
                color="#2D3748"
                fontFamily="DM Sans"
                textAlign={{
                  base: "center",
                  sm: "inherit",
                  md: "inherit",
                  lg: "inherit",
                }}
              >
                {Formatter.formatCurrency(dashboardData.custodyTwoMonthAgo)}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex w="100%" flexWrap="wrap" gap="1rem" className="dashboard-icon-3">
          <Flex
            w="100%"
            maxW="1034px"
            minH="450px"
            bg="#fff"
            borderRadius="1rem"
            p="1rem 2rem"
          >
            <Flex
              direction="column"
              alignItems={{
                base: "center",
                sm: "normal",
                md: "normal",
                lg: "normal",
              }}
            >
              <Text
                fontSize="1.25rem"
                fontWeight="700"
                color="#114B8F"
                fontFamily="DM Sans"
              >
                Passagens dos últimos 12 meses
              </Text>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={dashboardData.chargeCounts} width={730}>
                  <CartesianGrid strokeDasharray="12 1" />
                  <XAxis dataKey="label" />
                  <YAxis />
                  <Area
                    type="monotone"
                    dataKey="count"
                    stroke="#f26522"
                    fill="#f26522"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Flex>
          </Flex>

          <Flex w="100%" maxW="509px" direction="column" gap="1rem">
            <Flex
              w="100%"
              bg="#fff"
              borderRadius="1rem"
              p="1rem 2rem"
              justifyContent={{
                base: "center",
                sm: "flex-start",
                md: "flex-start",
                lg: "flex-start",
              }}
              gap="1rem"
              flexWrap={{
                base: "wrap",
                sm: "nowrap",
                md: "nowrap",
                lg: "nowrap",
              }}
            >
              <Flex
                bg="#FEEBCB99"
                h="72px"
                w="72px"
                borderRadius="50%"
                justifyContent="center"
                alignItems="center"
                fontSize="2.5rem"
              >
                <BsCalendar2DateFill />
              </Flex>
              <Flex
                direction="column"
                alignItems={{
                  base: "center",
                  sm: "normal",
                  md: "normal",
                  lg: "normal",
                }}
              >
                <Text
                  fontSize="0.875rem"
                  fontWeight="700"
                  color="#8E99AB"
                  fontFamily="DM Sans"
                >
                  Passagens de hoje
                </Text>
                <Text
                  fontSize="2.38rem"
                  fontWeight="700"
                  color="#2D3748"
                  fontFamily="DM Sans"
                >
                  {Formatter.formatNumber(dashboardData.passesToday)}
                </Text>
              </Flex>
            </Flex>

            <Flex
              w="100%"
              bg="#fff"
              borderRadius="1rem"
              p="1rem 2rem"
              justifyContent={{
                base: "center",
                sm: "flex-start",
                md: "flex-start",
                lg: "flex-start",
              }}
              gap="1rem"
              flexWrap={{
                base: "wrap",
                sm: "nowrap",
                md: "nowrap",
                lg: "nowrap",
              }}
            >
              <Flex
                bg="#FEEBCB99"
                h="72px"
                w="72px"
                borderRadius="50%"
                justifyContent="center"
                alignItems="center"
                fontSize="2.5rem"
              >
                <BsReplyFill />
              </Flex>
              <Flex
                direction="column"
                alignItems={{
                  base: "center",
                  sm: "normal",
                  md: "normal",
                  lg: "normal",
                }}
              >
                <Text
                  fontSize="0.875rem"
                  fontWeight="700"
                  color="#8E99AB"
                  fontFamily="DM Sans"
                >
                  Dia anterior
                </Text>
                <Text
                  fontSize="2.38rem"
                  fontWeight="700"
                  color="#2D3748"
                  fontFamily="DM Sans"
                >
                  {Formatter.formatNumber(dashboardData.passesYesterday)}
                </Text>
              </Flex>
            </Flex>

            <Flex
              w="100%"
              bg="#fff"
              borderRadius="1rem"
              p="1rem 2rem"
              justifyContent={{
                base: "center",
                sm: "flex-start",
                md: "flex-start",
                lg: "flex-start",
              }}
              gap="1rem"
              flexWrap={{
                base: "wrap",
                sm: "nowrap",
                md: "nowrap",
                lg: "nowrap",
              }}
            >
              <Flex
                bg="#FEEBCB99"
                h="72px"
                w="72px"
                borderRadius="50%"
                justifyContent="center"
                alignItems="center"
                fontSize="2.5rem"
              >
                <BsCalendar4Week />
              </Flex>
              <Flex
                direction="column"
                alignItems={{
                  base: "center",
                  sm: "normal",
                  md: "normal",
                  lg: "normal",
                }}
              >
                <Text
                  fontSize="0.875rem"
                  fontWeight="700"
                  color="#8E99AB"
                  fontFamily="DM Sans"
                >
                  Passagens semana anterior
                </Text>
                <Text
                  fontSize="2.38rem"
                  fontWeight="700"
                  color="#2D3748"
                  fontFamily="DM Sans"
                >
                  {Formatter.formatNumber(dashboardData.passesLastWeek)}
                </Text>
              </Flex>
            </Flex>

            <Flex
              w="100%"
              bg="#fff"
              borderRadius="1rem"
              p="1rem 2rem"
              justifyContent={{
                base: "center",
                sm: "flex-start",
                md: "flex-start",
                lg: "flex-start",
              }}
              gap="1rem"
              flexWrap={{
                base: "wrap",
                sm: "nowrap",
                md: "nowrap",
                lg: "nowrap",
              }}
            >
              <Flex
                bg="#FEEBCB99"
                h="72px"
                w="72px"
                borderRadius="50%"
                justifyContent="center"
                alignItems="center"
                fontSize="2.5rem"
              >
                <BsCalendar2DateFill />
              </Flex>
              <Flex
                direction="column"
                alignItems={{
                  base: "center",
                  sm: "normal",
                  md: "normal",
                  lg: "normal",
                }}
              >
                <Text
                  fontSize="0.875rem"
                  fontWeight="700"
                  color="#8E99AB"
                  fontFamily="DM Sans"
                >
                  Passagens mês anterior
                </Text>
                <Text
                  fontSize="2.38rem"
                  fontWeight="700"
                  color="#2D3748"
                  fontFamily="DM Sans"
                >
                  {Formatter.formatNumber(dashboardData.passesLastMonth)}
                </Text>
              </Flex>
            </Flex>

            <Flex
              w="100%"
              bg="#fff"
              borderRadius="1rem"
              p="1rem 2rem"
              justifyContent={{
                base: "center",
                sm: "flex-start",
                md: "flex-start",
                lg: "flex-start",
              }}
              gap="1rem"
              flexWrap={{
                base: "wrap",
                sm: "nowrap",
                md: "nowrap",
                lg: "nowrap",
              }}
            >
              <Flex
                bg="#FEEBCB99"
                h="72px"
                w="72px"
                borderRadius="50%"
                justifyContent="center"
                alignItems="center"
                fontSize="2.5rem"
              >
                <BsCalendar2DateFill />
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex w="100%">
          <Flex
            w="100%"
            bg="#fff"
            borderRadius="1rem"
            p="1rem 2rem"
            direction="column"
            gap="2rem"
          >
            <Text
              fontSize="1.25rem"
              fontWeight="700"
              color="#114B8F"
              fontFamily="DM Sans"
            >
              Pagamentos realizados
            </Text>
            <DataTable
              //title="Clientes"
              checkboxSelection={false}
              rows={dashboardData.payments}
              columns={columns}
              //pagination={resultInfo}
              //onPageChange={(page: number) => load(page)}
              //loading={loading}
            />
          </Flex>
        </Flex>

        <Flex w="100%">
          <Flex
            w="100%"
            bg="#fff"
            borderRadius="1rem"
            p="1rem 2rem"
            direction="column"
            gap="2rem"
          >
            <Text
              fontSize="1.25rem"
              fontWeight="700"
              color="#114B8F"
              fontFamily="DM Sans"
            >
              Passagens pendentes de pagamento (valor)
            </Text>
            <DataTable
              //title="Clientes"
              checkboxSelection={false}
              rows={dashboardData.openChargesTotal}
              columns={columns}
              //pagination={resultInfo}
              //onPageChange={(page: number) => load(page)}
              //loading={loading}
            />
          </Flex>
        </Flex>

        <Flex w="100%">
          <Flex
            w="100%"
            bg="#fff"
            borderRadius="1rem"
            p="1rem 2rem"
            direction="column"
            gap="2rem"
          >
            <Text
              fontSize="1.25rem"
              fontWeight="700"
              color="#114B8F"
              fontFamily="DM Sans"
            >
              Passagens (quantidade)
            </Text>
            <DataTable
              //title="Clientes"
              checkboxSelection={false}
              rows={dashboardData.chargesTotal}
              columns={columnsQty}
              //pagination={resultInfo}
              //onPageChange={(page: number) => load(page)}
              //loading={loading}
            />
          </Flex>
        </Flex>
      </Flex>
      {/* )} */}
    </Layout>
  )
}
