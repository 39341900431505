import { BalanceReturnBulk, SearchParams, SearchResult } from "../../entities"
import ApiClient from "../clients/ApiClient"
import QueryStringBuilder from "../../util/queryBuilder"

const client = new ApiClient()

export class BalanceReturnBulkRepository {
  public static async search(
    params: SearchParams,
    token: string
  ): Promise<SearchResult<BalanceReturnBulk>> {
    const queryStringBuilder = new QueryStringBuilder()
    for (const filter of params.filter) {
      queryStringBuilder.addParameter(filter.key, filter.value)
    }
    const queryString = queryStringBuilder.buildQueryString()

    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest(
      "GET",
      `/balance-return-bulk?${queryString}&page=${params.page}&limit=${params.limit}`,
      {},
      headers
    )

    const result = new SearchResult<BalanceReturnBulk>(
      response.data,
      response.info
    )

    return result
  }

  public static async csv(
    params: SearchParams,
    token: string
  ): Promise<string> {
    const headers = { Authorization: `Bearer ${token}` }
    const queryStringBuilder = new QueryStringBuilder()
    queryStringBuilder.addParameter("csv", "1")
    for (const filter of params.filter) {
      queryStringBuilder.addParameter(filter.key, filter.value)
    }
    const queryString = queryStringBuilder.buildQueryString()

    const response = await client.DoRequest(
      "GET",
      `/balance-return-bulk?${queryString}&page=${params.page}&limit=${params.limit}`,
      {},
      headers,
      true
    )

    return URL.createObjectURL(response)
  }

  public static async report(id: number, token: string): Promise<string> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest(
      "GET",
      `/balance-return-bulk/report/${id}`,
      {},
      headers,
      true
    )

    return URL.createObjectURL(response)
  }

  public static async get(
    id: number,
    token: string
  ): Promise<BalanceReturnBulk> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest(
      "GET",
      `/balance-return-bulk/${id}`,
      {},
      headers
    )

    return new BalanceReturnBulk(response)
  }

  public static async send(id: number, token: string): Promise<boolean> {
    const headers = { Authorization: `Bearer ${token}` }
    await client.DoRequest("POST", `/balance-return-bulk/send`, { id }, headers)

    return true
  }
}
