import { DashboardChargeCount } from "./DashboardChargeCount"
import { DashboardPayment } from "./DashboardPayment"

export class Dashboard {
  public id: number
  public numberOfClients: number
  public numberOfClientsThisMonth: number
  public numberOfClientsLastMonth: number
  public numberOfClientsTwoMonthAgo: number
  public custodyThisMonth: number
  public custodyLastMonth: number
  public custodyTwoMonthAgo: number
  public totalClientAccesses: number
  public loggedClientAccesses: number
  public anonymousClientAccesses: number
  public passesToday: number
  public passesYesterday: number
  public passesThisWeek: number
  public passesLastWeek: number
  public passesThisMonth: number
  public passesLastMonth: number
  public totalPaymentsToday: number
  public customersWithBalance: number
  public totalPaymentsYesterday: number
  public totalPaymentsThisWeek: number
  public totalPaymentsLastWeek: number
  public totalPaymentsThisMonth: number
  public totalPaymentsLastMonth: number
  public totalBalance: number

  public payments: DashboardPayment[] = []
  public openChargesTotal: DashboardPayment[] = []
  public chargesTotal: DashboardPayment[] = []

  public chargeCounts: DashboardChargeCount[] = []

  public constructor(data: any = {}) {
    this.id = data?.id || 0
    this.numberOfClients = data.numberOfClients || 0
    this.numberOfClientsThisMonth = data.numberOfClientsThisMonth || 0
    this.numberOfClientsLastMonth = data.numberOfClientsLastMonth || 0
    this.numberOfClientsTwoMonthAgo = data.numberOfClientsTwoMonthAgo || 0
    this.custodyThisMonth = data.custodyThisMonth || 0
    this.custodyLastMonth = data.custodyLastMonth || 0
    this.custodyTwoMonthAgo = data.custodyTwoMonthAgo || 0
    this.totalClientAccesses = data.totalClientAccesses || 0
    this.loggedClientAccesses = data.loggedClientAccesses || 0
    this.anonymousClientAccesses = data.anonymousClientAccesses || 0
    this.passesToday = data.passesToday || 0
    this.passesYesterday = data.passesYesterday || 0
    this.passesThisWeek = data.passesThisWeek || 0
    this.passesLastWeek = data.passesLastWeek || 0
    this.passesThisMonth = data.passesThisMonth || 0
    this.passesLastMonth = data.passesLastMonth || 0
    this.customersWithBalance = data.customersWithBalance || 0
    this.totalPaymentsToday = data.totalPaymentsToday || 0
    this.totalPaymentsYesterday = data.totalPaymentsYesterday || 0
    this.totalPaymentsThisWeek = data.totalPaymentsThisWeek || 0
    this.totalPaymentsLastWeek = data.totalPaymentsLastWeek || 0
    this.totalPaymentsThisMonth = data.totalPaymentsThisMonth || 0
    this.totalPaymentsLastMonth = data.totalPaymentsLastMonth || 0
    this.totalBalance = data.totalBalance || 0

    if (data.payments) {
      for (const payment of data.payments) {
        this.payments.push(new DashboardPayment(payment))
      }
    }

    if (data.openChargesTotal) {
      for (const openChargeTotal of data.openChargesTotal) {
        this.openChargesTotal.push(new DashboardPayment(openChargeTotal))
      }
    }

    if (data.chargesTotal) {
      for (const chargeTotal of data.chargesTotal) {
        this.chargesTotal.push(new DashboardPayment(chargeTotal))
      }
    }

    if (data.chargeCounts) {
      for (const chargeCount of data.chargeCounts) {
        this.chargeCounts.push(new DashboardChargeCount(chargeCount))
      }
    }
  }
}
